<template>
  <v-app>
    <v-main>
      <v-container fluid>
        <v-layout align-center justify-center>
          <v-flex xs12 sm10 md8>
            <h1
              style="
                text-align: center;
                float: center;
                font-size: 2.5em;
                filter: drop-shadow(2px 4px 8px #585858);
              "
            >
              ¡Gracias por tu compra!
            </h1>
            <div style="clear: both"></div>
            <h6 style="text-align: center; float: center; font-size: 2em">
              Revisa tu correo, recibirás noticias nuestras con toda la
              información acerca de tu compra.
            </h6>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import { BASE_URL } from "./constants.js";
import { STYLE } from "./constants.js";
import genericReload from "./genericReload.js";
import * as basics from "./basics.js";
export default {
  name: "Gracias",
  data: () => ({}),
  async mounted() {
    const selectedWorkshop = JSON.parse(localStorage.getItem("workshop"));
    const post = JSON.parse(localStorage.getItem("post"));
    const invita1 = JSON.parse(localStorage.getItem("invita1"));
    const invita2 = JSON.parse(localStorage.getItem("invita2"));
    const invita3 = JSON.parse(localStorage.getItem("invita3"));

    const headers = {
      "Content-Type": "application/json",
    };
    const existMain = await this.checkClient(post);
    let responseMain = null;
    if (existMain && existMain.length) {
      responseMain = await fetch(
        `${BASE_URL}/api/withoutTokenWorkshop/${selectedWorkshop.id}/client/${existMain[0].id}`,
        {
          method: "POST",
          headers: headers,
        }
      );
    } else {
      responseMain = await fetch(`${BASE_URL}/api/withoutTokenClient`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(post),
      });
      const existMain2 = await this.checkClient(post);
      const responseMain2 = await fetch(
        `${BASE_URL}/api/withoutTokenWorkshop/${selectedWorkshop.id}/client/${existMain2[0].id}`,
        {
          method: "POST",
          headers: headers,
        }
      );
    }
    if(invita1 && invita1.nombre != ""){
      if (invita1.email == ""){ invita1.lopd = 0; }
      invita1.idCentro = selectedWorkshop.idCentro;
      const exist1 = await this.checkClient(invita1);
      let response1 = null;
      if (exist1 && exist1.length) {
        response1 = await fetch(
          `${BASE_URL}/api/withoutTokenWorkshop/${selectedWorkshop.id}/client/${exist1[0].id}`,
          {
            method: "POST",
            headers: headers,
          }
        );
      } else {
        response1 = await fetch(`${BASE_URL}/api/withoutTokenClient`, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(invita1),
        });
        const exist12 = await this.checkClient(invita1);
        const response12 = await fetch(
          `${BASE_URL}/api/withoutTokenWorkshop/${selectedWorkshop.id}/client/${exist12[0].id}`,
          {
            method: "POST",
            headers: headers,
          }
        );
      }
    }
    if(invita2 && invita2.nombre != ""){
      if (invita2.email == ""){ invita2.lopd = 0; }
      invita2.idCentro = selectedWorkshop.idCentro;
      const exist2 = await this.checkClient(invita2);
      let response2 = null;
      if (exist2 && exist2.length) {
        response2 = await fetch(
          `${BASE_URL}/api/withoutTokenWorkshop/${selectedWorkshop.id}/client/${exist2[0].id}`,
          {
            method: "POST",
            headers: headers,
          }
        );
      } else {
        response2 = await fetch(`${BASE_URL}/api/withoutTokenClient`, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(invita2),
        });
        const exist22 = await this.checkClient(invita2);
        const response22 = await fetch(
          `${BASE_URL}/api/withoutTokenWorkshop/${selectedWorkshop.id}/client/${exist22[0].id}`,
          {
            method: "POST",
            headers: headers,
          }
        );
      }
    }
    if(invita3 && invita3.nombre != ""){
      if (invita3.email == ""){ invita3.lopd = 0; }
      invita3.idCentro = selectedWorkshop.idCentro;
      const exist3 = await this.checkClient(invita3);
      let response3 = null;
      if (exist3 && exist3.length) {
        response3 = await fetch(
          `${BASE_URL}/api/withoutTokenWorkshop/${selectedWorkshop.id}/client/${exist3[0].id}`,
          {
            method: "POST",
            headers: headers,
          }
        );
      } else {
        response3 = await fetch(`${BASE_URL}/api/withoutTokenClient`, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(invita3),
        });
        const exist32 = await this.checkClient(invita3);
        const response32 = await fetch(
          `${BASE_URL}/api/withoutTokenWorkshop/${selectedWorkshop.id}/client/${exist32[0].id}`,
          {
            method: "POST",
            headers: headers,
          }
        );
      }
    }
    switch (responseMain.status) {
      case 200:
        const fecha = selectedWorkshop.fecha.split(" ")[0];
        const postDataPago = {
          idClient: existMain[0].id,
          fecha: basics.getDate(),
          idCentro: selectedWorkshop.idCentro,
          idProfesional: 2,
          nombreBono: selectedWorkshop.tipo+": "+fecha+" - "+selectedWorkshop.nombre,
          precioBono: selectedWorkshop.precio,
          descuento: 0,
          pdescuento: 0,
          pago: "Pendiente de pago",
          codigo: "",
          nutricion: 0,
          psicologia: 0,
          fisioterapia: 0,
          entrenamiento: 0,
        };

        await fetch(
          `${BASE_URL}/api/withoutTokenFee`,
          {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(postDataPago),
          }
        );
        const headersEmail = {
          "Content-Type": "application/json",
        };
    
        let emailContent =
          post.nombre.toUpperCase().bold() +
          " " +
          post.apellidos.toUpperCase().bold() +
          " se ha unido al Taller " +
          selectedWorkshop.nombre + " del " + selectedWorkshop.fecha;
          if(post.email!=""){
            emailContent += ". Su email es " +
            post.email.bold();
          }
          if(post.telefono!=""){
            emailContent += ". Su teléfono es " +
            post.telefono.bold();
          }
        if(invita1 && invita1.nombre != ""){
          emailContent += ". Trae como invitado a "+invita1.nombre+" "+invita1.apellidos;
          if(invita1.email){
            emailContent += ". Email: "+invita1.email;
          }
          if(invita1.telefono){
            emailContent += ". Teléfono: "+invita1.telefono;
          }
        }
        if(invita2 && invita2.nombre != ""){
          emailContent += ". También trae como invitado a "+invita2.nombre+" "+invita2.apellidos;
          if(invita2.email){
            emailContent += ". Email: "+invita2.email;
          }
          if(invita2.telefono){
            emailContent += ". Teléfono: "+invita2.telefono;
          }
        }
        if(invita3 && invita3.nombre != ""){
          emailContent += ". Y también a "+invita3.nombre+" "+invita3.apellidos;
          if(invita3.email){
            emailContent += ". Email: "+invita3.email;
          }
          if(invita3.telefono){
            emailContent += ". Teléfono: "+invita3.telefono;
          }
        }
        const emailSubject = "Nueva COMPRA DE TALLER";
    
        const emailData = {
          email: "comunicacion@lahabitacionsaludable.com",
          content: emailContent,
          subject: emailSubject,
        };
        await fetch(`${BASE_URL}/api/mailing`, {
          method: "POST",
          headers: headersEmail,
          body: JSON.stringify(emailData),
        });

        const getContent = await genericReload("", "api/withoutTokenMessage");
        const messages = await getContent.json();
        const load = messages.find((message) => message.id === 26);
        const content = load.contenido;
        const subject = load.asunto;
        const emailClientData = {
          email: post.email,
          content: `${STYLE}` + content,
          subject: subject,
        };
        await fetch(`${BASE_URL}/api/mailing`, {
          method: "POST",
          headers: headersEmail,
          body: JSON.stringify(emailClientData),
        });
        if(invita1 && invita1.email != ""){
          const emailClientData1 = {
            email: invita1.email,
            content: `${STYLE}` + content,
            subject: subject,
          };
          await fetch(`${BASE_URL}/api/mailing`, {
            method: "POST",
            headers: headersEmail,
            body: JSON.stringify(emailClientData1),
          });
        }
        if(invita2 && invita2.email != ""){
          const emailClientData2 = {
            email: invita2.email,
            content: `${STYLE}` + content,
            subject: subject,
          };
          await fetch(`${BASE_URL}/api/mailing`, {
            method: "POST",
            headers: headersEmail,
            body: JSON.stringify(emailClientData2),
          });
        }
        if(invita3 && invita3.email != ""){
          const emailClientData3 = {
            email: invita3.email,
            content: `${STYLE}` + content,
            subject: subject,
          };
          await fetch(`${BASE_URL}/api/mailing`, {
            method: "POST",
            headers: headersEmail,
            body: JSON.stringify(emailClientData3),
          });
        }
        break;
    }
  },
  methods: {
    async checkClient(client) {
      if(client.email || client.telefono){
        const ruta =
          "api/withoutTokenClient/?email=" +
          client.email +
          "&telefono=" +
          client.telefono;
        const responseClient = await genericReload(this.$store.state.auth, ruta);
        return await responseClient.json();
      }else{
        return 0;
      }
    },
  },
};
</script>
<style scoped>
h1 {
  color: #31e9f5;
  text-align: center;
  padding-bottom: 5%;
}
</style>
