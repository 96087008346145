<template>
  <v-app>
    <v-main>
      <v-container fluid>
        <v-layout align-center justify-center>
          <v-flex xs12 sm10 md8>
            <h1
              style="
                text-align: center;
                float: center;
                font-size: 2.5em;
                filter: drop-shadow(2px 4px 8px red);
              "
            >
              Ha habido un error en tu compra...
            </h1>
            <div style="clear: both"></div>
            <h6 style="
                text-align: center;
                float: center;
                font-size: 2em;">
              Escríbenos y haznos saber tu error por si podemos atenderte por otro medio. Gracias y disculpa las molestias
            </h6>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
export default {
  name: "Error",
  data: () => ({}),
};
</script>
<style scoped>
h1 {
  color: red;
  text-align: center;
  padding-bottom: 5%;
}
</style>
